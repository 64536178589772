<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <el-row :gutter="20">
      <el-col :span="20" :offset="2">
        <header class="page-header">
          <el-row :gutter="20" align="middle">
            <el-col :span="12">
              <h2>Агуулахын жагсаалт</h2>
            </el-col>
            <el-col :span="12">
              <div class="actions text-right">
                <router-link to="/add-warehouse">
                  <el-button type="success" class="item">Нэмэх</el-button>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </header>
        <!-- Tabled content -->
        <div class="panel tabled">
          <el-tabs v-model="activeTabStatus">
            <el-tab-pane label="All" name="all">
              <div class="panel-content">
                  <div class="pl20 pr20 table-filter mb10">
                      <el-row :gutter="10" align="middle" type="flex">
                          <el-col :span="20">
                              <el-input placeholder="Хайх" class="input-with-select" v-model="search">
                                  <el-button icon="el-icon-search" slot="prepend"></el-button>
                              </el-input>
                          </el-col>
                          <el-col :span="4" :offset="4" class="text-right">
                              <el-dropdown @command="handleSort">
                                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                                  <el-dropdown-menu slot="dropdown">
                                      <el-dropdown-item command = 'firstChar'>
                                          Нэр А - Я
                                      </el-dropdown-item>
                                      <el-dropdown-item command = 'lastChar'>
                                          Нэр Я - А
                                      </el-dropdown-item>
                                      <el-dropdown-item command = 'newest'>
                                          Шинэ эхэнд
                                      </el-dropdown-item>
                                      <el-dropdown-item command = 'oldest'>
                                          Хуучин эхэнд
                                      </el-dropdown-item>
                                  </el-dropdown-menu>
                              </el-dropdown>
                          </el-col>
                      </el-row>
                  </div>
              </div>
              <div class="panel-content">
                <el-table
                  v-loading="loading"
                  :data="warehouses"
                  style="width: 100%"
                  @cell-click="clickCell"
                >
                  <el-table-column label="Монгол нэр"
                    prop="name_mon"
                  >
                  </el-table-column>
                  <el-table-column label="Англи нэр"
                    prop="name_eng"
                  ></el-table-column>
                  <el-table-column label="Хаяг"
                    prop="address"
                  ></el-table-column>
                   <el-table-column label="Нийлүүлэгч"
                    prop="supplier_mon_name"
                  ></el-table-column>
                </el-table>
                <el-pagination
                  @current-change = "curentPageHandler"
                  @size-change = "sizeChangeHandler"
                  class="text-right mt10"
                  background
                  :current-page.sync="currentPage"
                  :page-size="pageSize"
                  :page-sizes="[5, 20, 50, 100]"
                  layout="total, sizes, prev, pager, next"
                  :total="totalCount">
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// // @ is an alias to /src
import services from '../../../helpers/services'
export default {
  name: 'Warehouse',
  data () {
    return {
      suppliers: [],
      search: '',
      searchText: '',
      status: 'all',
      tableData: null,
      warehouses: [],
      loading: false,
      activeTabStatus: 'all',
      pageSize: 20,
      currentPage: 1,
      totalCount: 0
    }
  },
  async mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.pageFrom = from
    this.pageSize = size
    this.getSuppliers()
  },
  methods: {
    getSuppliers () {
      this.loading = true
      const query = '?search_text=' + '' + '&from=' + 0 + '&size=' + 100 + '&field=' + '' + '&sort=' + ''
      services.getSuppliers(query).then(response => {
        if (response.status === 'success') {
          this.suppliers = response.data
          this.loading = false
          this.getWarehousesBySupplierId()
        } else {
          this.loading = false
        }
      })
    },
    async sizeChangeHandler (size) {
      this.pageSize = size
      this.$router.push({ name: 'warehouseList' })
      this.getWarehousesBySupplierId()
    },

    async curentPageHandler (current) {
      this.currentPage = current
      this.$router.push({ name: 'warehouseList' })
      this.getWarehousesBySupplierId()
    },
    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.name_mon.localeCompare(b.name_mon))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.name_mon.localeCompare(a.name_mon))
      } else if (method === 'newest') {
        data.sort((a, b) => b.created_at.localeCompare(a.created_at))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.created_at.localeCompare(b.created_at))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.warehouses = this.sortMethods(data, this.warehouses)
    },
    async getWarehousesBySupplierId () {
      this.loading = true
      const tempData = []
      this.suppliers.forEach(el => {
        if (el.supplier_id) {
          tempData.push(el.supplier_id)
        }
      })
      const body = {
        supplier_id: tempData,
        size: 100,
        from: 0
      }
      services.getWarehousesBySupplierId(body).then(response => {
        this.loading = false
        if (response.status === 'success' && response.data) {
          this.warehouses = response.data
          this.totalCount = response.total
        }
      })
    },

    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'UpdateWarehouse',
          params: {
            warehouse_id: row.warehouse_id
          }
        }
      )
    }
  }
}
</script>
<style>
.el-table .cell {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
